'use strict';

function AnimateIn($node){
	this.$node = null;
	this.offsetLimit = 100;
	this.init($node);
};
AnimateIn.prototype.init = function($node){
	this.initNode($node);
	this.initEvents();
};
AnimateIn.prototype.initNode = function($node){
	this.$node = $($node);
};
AnimateIn.prototype.initEvents = function(){
	this.timer = setTimeout($.proxy(this.onLoad, this), 3000);

	$(window)
		.on('load', $.proxy(this.onLoad, this))
	;
};
AnimateIn.prototype.check = function(){
	var scrollTop = $(window).scrollTop();
	var scrollBottom = scrollTop + $(window).height();
	var offsetTop = this.$node.offset().top;
	var offsetHalf = offsetTop + Math.min(this.offsetLimit, this.$node.outerHeight() / 2);
	var offsetBottom = offsetTop + this.$node.outerHeight();
	if ((scrollTop <= offsetHalf && offsetHalf <= scrollBottom) || (offsetTop <= scrollTop && scrollBottom <= offsetBottom)) {
		this.$node.addClass('animate');
		$(window)
			.off('resize scroll', this._onChange)
		;
	}
};
AnimateIn.prototype.onLoad = function(e){
	clearTimeout(this.timer);

	this._onChange = this.onChange.bind(this);
	$(window)
		.on('resize scroll', this._onChange)
	;
	this.check();
};
AnimateIn.prototype.onChange = function(e){
	this.check();
};
