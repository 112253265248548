'use strict';

function MainMenu($node, conf){
	this.$node = null;
	this.init($node, conf);
};
MainMenu.prototype.init = function($node, conf){
	this.initNode($node);
	this.initEvents();
	this.setCurrent();
};
MainMenu.prototype.initNode = function($node){
	this.$node = $($node);
};
MainMenu.prototype.initEvents = function(){
	this.$node
		.on('click', '.toggle', $.proxy(this.onClick, this))
	;
	$(document)
		.on('click', $.proxy(this.onClickDocument, this))
	;
};
MainMenu.prototype.setCurrent = function(){
	$('body.cat-land')
		.find(this.$node.find('[href^="/land/"]'))
		.addClass('current')
	;
	$('body.cat-facility')
		.find(this.$node.find('[href^="/facility/"]'))
		.addClass('current')
	;
	$('body.cat-container')
		.find(this.$node.find('[href^="/container/"]'))
		.addClass('current')
	;
	$('body.cat-feature')
		.find(this.$node.find('[href^="/feature/"]'))
		.addClass('current')
	;
};
MainMenu.prototype.onClick = function(e){
	e.preventDefault();
	if (this.$node.is('.open')) {
		this.$node.removeClass('open');
		this.$node.addClass('close');
	} else {
		this.$node.addClass('open');
		this.$node.removeClass('close');
	}
};
MainMenu.prototype.onClickDocument = function(e){
	if (!$(e.target).closest(this.$node).length) {
		this.$node.removeClass('open');
		this.$node.addClass('close');
	}
};
