'use strict';

function Accordion($node){
	this.$node = null;
	this.$toggle = null;
	this.$body = null;
	this.$content = null;
	this.init($node);
};
Accordion.prototype.init = function($node){
	this.initNode($node);
	this.initEvents();
};
Accordion.prototype.initNode = function($node){
	this.$node = $($node);
	this.$toggle = this.$node.find('.accordion-toggle').eq(0);
	this.$body = this.$node.find('.accordion-body').eq(0);
	this.$content = this.$body.find('.accordion-content').eq(0);
};
Accordion.prototype.initEvents = function(){
	this.$node
		.on('click', $.proxy(this.onClick, this))
	;
};
Accordion.prototype.open = function(){
	var height = this.$body.height();
	this.$body.css({ height: height });

	var toHeight = this.$content.outerHeight();
	this.$node.addClass('open');
	this.$body.stop().animate({ height: toHeight }, 200, function(){
		$(this).css({ height: '' });
	});
};
Accordion.prototype.close = function(){
	var height = this.$body.height();
	this.$body.css({ height: height });

	this.$node.removeClass('open');
	this.$body.stop().animate({ height: 0 }, 200);
};
Accordion.prototype.toggle = function(){
	if (this.$node.is('.open')) {
		this.close();
	} else {
		this.open();
	}
};
Accordion.prototype.onClick = function(e){
	if (!$(e.target).closest('a, button').length) {
		e.preventDefault();
		this.toggle();
	}
};
