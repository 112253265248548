'use strict';

function Gallery($node){
	this.$node = null;
	this.$items = null;
	this.$selector = null;
	this.index = 0;
	this.init($node);
};
Gallery.prototype.init = function($node){
	this.initNode($node);
	this.initEvents();
	this.layout();
	this.select(0);
};
Gallery.prototype.initNode = function($node){
	this.$node = $($node);
	this.$items = this.$node.find('.items');
	this.$selector = this.$node.find('.selector');
};
Gallery.prototype.initEvents = function(){
	this.$node
		.on('click', '.prev', $.proxy(this.onClickPrev, this))
		.on('click', '.next', $.proxy(this.onClickNext, this))
	;
	this.$selector
		.on('click', 'a', $.proxy(this.onClickSelector, this))
	;

	this.$items
		.on('mousedown touchstart', $.proxy(this.onMouseDown, this))
		.on('mousemove touchmove', $.proxy(this.onMouseMove, this))
	;

	$(document)
		.on('mouseleave mouseup touchend', $.proxy(this.onMouseUp, this))
	;
};
Gallery.prototype.layout = function(){
	this.$items.css({ width: this.$items.find('.item').length * 100 + '%' });
};
Gallery.prototype.select = function(index){
	var length = this.$items.find('.item').length;
	index = (index + length) % length;
	this.$items.css({ left: (-100 * index) + '%' });
	this.index = index;
	this.$selector
		.find('a').removeClass('selected')
		.eq(this.index).addClass('selected')
	;
};
Gallery.prototype.prev = function(){
	this.select(this.index - 1);
};
Gallery.prototype.next = function(){
	this.select(this.index + 1);
};
Gallery.prototype.onClickSelector = function(e){
	e.preventDefault();
	this.select($(e.currentTarget).closest('li').index());
};
Gallery.prototype.onClickPrev = function(e){
	e.preventDefault();
	this.prev();
};
Gallery.prototype.onClickNext = function(e){
	e.preventDefault();
	this.next();
};
Gallery.prototype.onMouseDown = function(e){
	if (!e.originalEvent.touches) {
		e.preventDefault();
	}
	var x = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
	this._touchFrom = x;
};
Gallery.prototype.onMouseMove = function(e){
	if (this._touchFrom) {
		var x = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
		this._touchTo = x;
		this.$items.stop().css({marginLeft: this._touchTo - this._touchFrom });
	}
};
Gallery.prototype.onMouseUp = function(e){
	if (this._touchFrom && this._touchTo) {
		var offset = 70;
		this.$items.stop().animate({marginLeft: 0 }, 400);
		if (this._touchTo - this._touchFrom > offset) {
			this.prev();
		}
		if (this._touchTo - this._touchFrom < -offset) {
			this.next();
		}
	}

	delete this._touchFrom;
	delete this._touchTo;
};
