'use strict';

function AutoScroll($node){
	this.$node = null;
	this.offset = 0;
	this.init($node);
};
AutoScroll.prototype.init = function($node){
	this.initNode($node);
	this.initEvents();
};
AutoScroll.prototype.initNode = function($node){
	this.$node = $($node);
};
AutoScroll.prototype.initEvents = function(){
	this.$node
		.on('click', '[href^="#"]', $.proxy(this.onClick, this))
	;
};
AutoScroll.prototype.scrollTo = function($node){
	var top = $node.offset().top - this.offset;
	return new Promise(
		function(resolve, reject){
			$('html,body')
				.stop()
				.animate({ scrollTop: top }, 400, resolve)
			;
		}
	);
};
AutoScroll.prototype.onClick = function(e){
	var $link = $(e.currentTarget);
	var hash = $link.attr('href');
	if (hash != '#' && $(hash).length) {
		e.preventDefault();
		this.scrollTo($(hash)).then(function(){
			if (history.pushState) {
				history.pushState(null, null, hash);
			}
		});
	}
};
