'use strict';

jQuery(function($){
	$(document).each(function(index, node){
		new AutoScroll(node);
	})
	$('.site-header').each(function(index, node){
		new SiteHeader(node);
	})
	$('.site-header .navigation').each(function(index, node){
		new MainMenu(node);
	})
	$('.site-submenu').each(function(index, node){
		new Menu(node);
	})
	$('.banner-inquiry').each(function(index, node){
		new BannerInquiry(node);
	})
	$('.accordion').each(function(index, node){
		new Accordion(node);
	})
	$('.animate-in').each(function(index, node){
		new AnimateIn(node);
	})
	$('.block-gallery').each(function(index, node){
		new Gallery(node);
	})
});
