'use strict';

function BannerInquiry($node, conf){
	this.$node = null;
	this.init($node, conf);
};
BannerInquiry.prototype.init = function($node, conf){
	this.initNode($node);
	this.initEvents();
	this.load();
};
BannerInquiry.prototype.initNode = function($node){
	this.$node = $($node);
};
BannerInquiry.prototype.initEvents = function(){
	$(window)
		.on('scroll resize load', $.proxy(this.onChange, this))
		.on('scroll', $.proxy(this.onScroll, this))
	;

	this.$node
		.on('click', '.close', $.proxy(this.onClickClose, this))
	;
	this.$node
		.on('click', $.proxy(this.onClick, this))
	;
};
BannerInquiry.prototype.layout = function(){
	var scrollBottom = $(window).scrollTop() + (window.innerHeight || $(window).height());
	var nodeBottom = this.$node.offset().top + this.$node.height();
	if (scrollBottom < nodeBottom) {
		this.$node.addClass('fixed');
	} else {
		this.$node.removeClass('fixed');
	}
};
BannerInquiry.prototype.load = function(){
	if (sessionStorage.getItem('BannerInquiry.opened')) {
		this.open();
	} else {
		this.close();
	}
};
BannerInquiry.prototype.save = function(){
	if (this.$node.is('.closed')) {
		sessionStorage.removeItem('BannerInquiry.opened');
	} else {
		sessionStorage.setItem('BannerInquiry.opened', true);
	}
};
BannerInquiry.prototype.close = function(){
	this.$node.addClass('closed');
	this.save();
};
BannerInquiry.prototype.open = function(){
	this.$node.removeClass('closed');
	this.save();
};
BannerInquiry.prototype.onChange = function(){
	this.layout();
};
BannerInquiry.prototype.onClickClose = function(e){
	this.close();
};
BannerInquiry.prototype.onClick = function(e){
	if (this.$node.is('.closed') && !$(e.target).closest('.close').length) {
		this.open();
	}
};
BannerInquiry.prototype.onScroll = function(e){
	this.$node.addClass('scrolled');
	clearTimeout(this.timer);
	this.timer = setTimeout($.proxy(function(){
		this.$node.removeClass('scrolled');
	}, this), 100);
};
